import React, { FC } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import { observer } from 'mobx-react'

import Home from 'pages/Home'
import Auth from 'pages/Auth'

import useStore from '../store'
import { RouteProps } from 'react-router'

export enum Path {
  AUTH = '/auth',
  LOGIN = '/auth/login',
  CODE = '/auth/code',
  CONFIRM = '/auth/confirm',
  TERMS = '/auth/terms',

  HOME = '/home',
  MOCK = '/home/mock',

  NOT_FOUND = '/404'
}

export const PrivateRoute: FC<RouteProps> = observer(props => {
  const { user } = useStore()
  return (
    user && user.isAuth
      ? <Route {...props} />
      : <Redirect to={Path.AUTH} />
  )
})

export const AuthedRoute: FC<RouteProps> = observer(props => {
  const { user } = useStore()
  return (
    user && user.isAuth
      ? <Redirect to={Path.HOME} />
      : <Route {...props} />
  )
})

function Routes() {
  return (
    <Switch>
      <AuthedRoute path={Path.AUTH} component={Auth} />
      <PrivateRoute path={Path.HOME} component={Home} />
      <Redirect exact from='/' to={Path.HOME} />
      <Redirect to={Path.NOT_FOUND} />
    </Switch>
  )
}

export default observer(Routes)
