import React, { FC } from 'react'
import styled from 'styled-components'
import Page from 'core/Page'

import { default as TermsComponent } from 'components/Terms'
import { Row, Space, Text, theme } from 'ui'
import { Button, ButtonProps } from '@material-ui/core'
import useStore from 'store'
import { useTranslation } from 'react-i18next'
import { Redirect } from "react-router-dom"
import { Path } from 'core/Routes'

interface Props {

}

const StyledButton = styled(Button) <ButtonProps>`
  height:50px;
  background-color: ${theme.color.cerulean};
  border-radius: 5px;
  opacity:${p => p.opacity ? p.opacity : null};
  color:white;
  padding: 0 1rem;
  :hover {
    background-color: ${theme.color.cerulean};
  }
`

const StyledButtonOpac = styled(Button) <ButtonProps>`
  background-color: transparent;
  border-radius: 5px;
  opacity:${p => p.opacity ? p.opacity : null};
  color: #0d7fdf;
  :hover {
    background-color: transparent;
  }
`

const StyledPage = styled(Page)`
  background-color:${theme.color.loginBackground};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  width: 80vw;
   @media (max-width: 768px) {
    width: 100vw;
  }
  padding: 1rem;
  height: 100vh;
  overflow: scroll;
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Title = styled.div`
  font-size: 1.3rem;
`

const Terms: FC<Props> = () => {
  const { user } = useStore()
  const { t } = useTranslation('auth')

  if (!user.phone && !user.email) return <Redirect to={Path.LOGIN} />

  return (
    <StyledPage>
      <Wrapper>
        <Flex>
          <Title>{t`terms_title`}</Title>
          <Space />
          <Text>{t`terms_desc`}</Text>
          <Space />
          <a href='https://media.mekome.services/images-and-docs/mekomeTermsOfUse.pdf' download>
            {t`save_pdf`}
          </a>
        </Flex>
        <Space height={2} />
        <TermsComponent />
        <Space height={2} />
        <Flex>
          <StyledButton onClick={() => user.setIsAuth(true)}>
            {t`confirm`}
          </StyledButton>
        </Flex>
        <Space height={2} />
      </Wrapper>
    </StyledPage>
  )
}

export default Terms
