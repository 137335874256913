import React from 'react'
import { observer } from 'mobx-react'
import useStore from 'store'
import HomeRoutes from 'pages/Home/HomeRoutes'
import Layout from '../../core/Layout'
import Loading from 'core/Loading'

function Home() {
  const { user: userStore, messages } = useStore()
  React.useEffect(() => {
    if (userStore.isAuth) {
      userStore.init()
    }
  }, [userStore.isAuth])
  if (userStore.loading || messages.isLoading) {
    return <Loading />
  }
  return (
    <Layout>
      <HomeRoutes />
    </Layout>
  )
}

export default observer(Home)
