import React, { } from 'react'
import { Button } from '@material-ui/core'
import useStore from 'store'
import { theme, Space, Row, Icon } from 'ui'
import Text from 'ui/Text/Text'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import MessagesFeed from './MessagesFeed/MessagesFeed'
import AddIcon from '@material-ui/icons/Add';
import MessageDialog from 'pages/Home/MessageDialog/MessageDialog'
import Grow from 'ui/Grow'
import UserList from 'components/Users/UserList'
import isMobile from 'utils/isMobile'

const StyledAddIcon = styled(AddIcon) <{ iconColor: string }>`
  color:${p => p.iconColor};
`
const HomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`
const MessagesContainer = styled.div`
  display:flex;
  flex:1;
  position:relative;
  flex-direction:column;
  background-color:${theme.color.backgroundGray};
`
const EmptyContainer = styled.div`
  display:flex;
  flex-direction:column;
  flex:1;
`
const Header = styled(Row)`
  padding:1rem 2rem;
  background-color:${theme.color.backgroundGray};
  border-bottom:1px solid ${theme.color.gray};
  display:flex;
  justify-content:space-between;
`
const EmptyAddMessageContainer = styled.div`
  cursor:pointer;
  border-radius:8px;
  position:relative;
  display:flex;
  flex-direction:column;
  background-color:${theme.color.white};
  box-shadow: 0 2px 8px 0 ${theme.color.shadow};
  padding:1rem;
  align-items:center;
  margin:0.5rem;
`
const Circle = styled.div<{ color: string, backgroundColor: string }>`
    border-radius: 50%;
    background-color: ${p => p.backgroundColor};
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${p => p.color};
    cursor: pointer;
    `
const EmptyMessageCircle = styled(Circle)`
  position:absolute;
  bottom:-15px;
`
const EmptyItems = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`
const Empty: React.FC = () => {
  const { newMessage, user } = useStore()
  const { t } = useTranslation('home')

  const onClickCreateCulturalMessage = () => {
    newMessage.openMessageDialog()
    newMessage.setMessageLabel('תרבות')
  }
  const onClickCreateSecurityMessage = () => {
    newMessage.openMessageDialog()
    newMessage.setMessageLabel('בטחון')
  }
  const onClickCreateGeneralMessage = () => {
    newMessage.openMessageDialog()
    newMessage.setMessageLabel('כללי')
  }

  const onClickCreategeneralMessage = (label) => {
    newMessage.openMessageDialog()
    newMessage.setMessageLabel(label)
  }

  return (
    <EmptyContainer>
      <EmptyItems>
        <Text weight='bold' size='lg'>{t`noMessages`}</Text>
        <Space height={1} />
        <Text weight='medium' textcolor={theme.color.darkGray}>{t`canCreateMessage`}</Text>
        <Space height={2} />
        <Row>

          {user.labels.map(label =>   
            <>
              {label == 'תרבות' && 
                <EmptyAddMessageContainer onClick={onClickCreateCulturalMessage}>
                  <Icon name='Plays' size={50} />
                  <Text>{t`cultureMessages`}</Text>
                  <EmptyMessageCircle backgroundColor={theme.color.culturalPurple} color={theme.color.white}>
                    <StyledAddIcon iconColor={theme.color.white} />
                  </EmptyMessageCircle>
                </EmptyAddMessageContainer>    
              }

              {label == 'בטחון' &&
                 <EmptyAddMessageContainer onClick={onClickCreateGeneralMessage}>
                 <Icon name='global_messages' size={50} />
                 <Text>{t`generalMessages`}</Text>
                 <EmptyMessageCircle backgroundColor={theme.color.urgentBlue} color={theme.color.white}>
                   <StyledAddIcon iconColor={theme.color.white} />
                 </EmptyMessageCircle>
               </EmptyAddMessageContainer>     
              }

              {label == 'כללי' &&
               <EmptyAddMessageContainer onClick={onClickCreateSecurityMessage}>
                <Icon name='tzachy' size={50} />
                <Text>{t`securityMessages`}</Text>
                <EmptyMessageCircle backgroundColor={theme.color.cluckingRed} color={theme.color.white}>
                  <StyledAddIcon iconColor={theme.color.white} />
                </EmptyMessageCircle>
              </EmptyAddMessageContainer>} 

              {!['כללי', 'בטחון', 'תרבות'].includes(label) &&
               <EmptyAddMessageContainer onClick={() => onClickCreategeneralMessage(label)}>
                <Icon name='arnakd' size={50} />
                <Text>{label}</Text>
                <EmptyMessageCircle backgroundColor={theme.color.cluckingRed} color={theme.color.white}>
                  <StyledAddIcon iconColor={theme.color.white} />
                </EmptyMessageCircle>
              </EmptyAddMessageContainer>} 
            </>
          )}
        </Row>
      </EmptyItems>
      <Grow />
    </EmptyContainer>
  )
}
const Messages: React.FC = () => {
  const { t } = useTranslation('app')
  const { newMessage, messages, user } = useStore()
  const isEmpty = messages.messages.length === 0

  return (
    <MessagesContainer>
      <Header>
        <Row flex={1}>
          <Text weight='medium'>סה”כ מסרונים נשלחו בתקופת החיוב: {user.userInfo?.smsCounter}</Text>
        </Row>
        {!isEmpty && (
          <Row flex={1} justify='stretch'>
            <Button
              onClick={() => newMessage.openMessageDialog()}
            >
              <Text weight='bold'>{t`messageItem:new_message`}</Text>
              <Space width={1} />
              <Circle color={theme.color.white} backgroundColor={theme.color.CommunityYellow}>
                <StyledAddIcon iconColor={theme.color.black} />
              </Circle>
            </Button>
          </Row>
        )}
      </Header>
      <HomeContainer>
        {isMobile() && user.userListTableView ? <UserList /> : ''}
        {isEmpty ? <Empty /> : <MessagesFeed />}
        {!isMobile() && user.userListTableView ? <UserList /> : ''}
      </HomeContainer>      
      <MessageDialog />
    </MessagesContainer>
  )
}

export default observer(Messages)