import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { Path } from 'core/Routes'
import styled from 'styled-components'
import MessagesFeed from 'components/Messages/MessagesFeed/MessagesFeed'
import Messages from 'components/Messages/Messages'

const Content = styled.div`
  display:flex;
  flex:1;
  margin: 0 !important;
  background: white;
  border-radius: 5px;
`

const Wrap = styled.div`
  flex: 1;
  overflow:scroll;
  display: flex;
`

const Mock = ({ ...props }) => <Messages />

const HomeRoutes = () => {
  return (
    <Switch>
      <Route path={Path.HOME} component={Mock} />
      <Redirect exact from={Path.MOCK} to={Path.HOME} />
    </Switch>
  )
}

export default HomeRoutes
