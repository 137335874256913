import React, { useEffect, useState } from 'react'
import useStore from 'store'
import { theme, Space } from 'ui'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { Button, Table, TableBody, TableCell, TableRow, TableHead } from '@material-ui/core'
import { Phone as PhoneIcon, Mail as MailIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  height: 80vh;
  @media (max-width: 768px) {
    height: 400px;
  }
`
const Wrap = styled.div`
  position: relative;
  padding: 1px 1rem 1px 0;
  display:flex;
  /*flex:2;*/
  background-color:${theme.color.backgroundGray};
  font-size: 12px;
  padding: 1px 1rem; 
  overflow: auto;
`
const Phone = styled.div`
  margin-bottom: 0.5rem;
  font-size:12px;
`
const NumberLink = styled.a`
  
`
const Mail = styled.div`
  margin-bottom: 0.5rem;
  font-size:12px;
`
const MailLink = styled.a`
  direction: ltr;
`

const UserList: React.FC = () => {
  const { user } = useStore()
  const { t } = useTranslation('membersTable')
  
  return (
    <Container>
      <Wrap>
        <Space height={1} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{t`name`}</TableCell>
              <TableCell>{t`title`}</TableCell>
              <TableCell>{t`email`}</TableCell>       
              <TableCell>{t`phone`}</TableCell>    
            </TableRow>
          </TableHead>
    <TableBody>
    {user.concileUsers.length > 0 
      ? user.concileUsers.map((user, i) =>  
        <TableRow key={`map_log_row_${i}`}  style={{ background: 'white' }}>
          <TableCell>{i+1}</TableCell>
          <TableCell>{user.givenName + ' ' + user.familyName}</TableCell>
          <TableCell>{user.nickName}</TableCell>
          <TableCell>{Array.isArray(user.emails) ? user.emails.map(e => <Mail><MailLink href={`mailto:${e}`}>{e}</MailLink></Mail>) : ''}</TableCell>
          <TableCell style={{direction: 'ltr'}}>{Array.isArray(user.phones) ? user.phones.map(p => <Phone><NumberLink href={`tel:${p}`}>{p}</NumberLink></Phone>) : ''}</TableCell>
        </TableRow>)
      : <TableRow><TableCell colSpan={5}>שולחן ריק</TableCell></TableRow>}
  </TableBody>
    </Table>
      </Wrap>
    </Container>
  )
}

export default observer(UserList)