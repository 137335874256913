import React, { createContext, FC, useContext, useEffect, useState } from 'react'

import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import { Check, Clear } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

interface Props {
}

export const ConfirmDialogContext = createContext([() => {}, undefined])

export function useConfirmDialog() {
  return useContext(ConfirmDialogContext)
}

const ConfirmDialogProvider: FC = ({ children }) => {

  const [cb, setCb] = useState(undefined)
  const { t } = useTranslation('app')

  const handleOk = () => (cb && cb()) || setCb(undefined)
  const openWith = fn => () => setCb(() => { return fn })

  return (
    <>
      <Dialog open={!!cb} onClose={openWith(undefined)} fullWidth maxWidth='xs'>
        <DialogTitle>
          {t`sure`}
        </DialogTitle>
        <DialogActions>
          <Button startIcon={<Check />} onClick={handleOk}>{t`ok`}</Button>
          <Button startIcon={<Clear />} onClick={openWith(undefined)}>{t`cancel`}</Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialogContext.Provider value={[openWith, open]}>
        {children}
      </ConfirmDialogContext.Provider>
    </>
  )
}

export default ConfirmDialogProvider
