import React, { FC } from 'react'
import styled from 'styled-components'
import { theme, Text, Row, Space } from 'ui'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import useStore from 'store'
import { selectGroup } from 'store/newMessage.store'
import { observer } from 'mobx-react'

const StyledRadio = styled(Radio)`
  &.MuiRadio-root {
    width: 20px;
    height: 20px;
    color: ${theme.color.communityBlue};
    margin: 6px;
    padding: 0;
  }
`

const SelectGroup: FC<any> = () => {
  const { t } = useTranslation('MessageDialog')
  const { newMessage, user } = useStore()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    newMessage.updateGroupSelect(event.target.value);
  };
  return (
    <Row style={{ alignItems: 'center', justifyContent: 'flex-start', }}>
      <Text
        textcolor={theme.color.black + 66}
        weight={'normal'}
        size={'md'}
      >
        {t`selectGroup` + ':'}
      </Text>
      <Space width={1} />
      <FormControl
        component="fieldset"
        style={{ flexDirection: 'row' }}

      >
        <RadioGroup
          aria-label="secommunitylect-"
          value={newMessage.newMessage.label}
          onChange={handleChange}
          row
        >
          {user.labels.map(el => (
            <FormControlLabel
              color={theme.color.communityBlue}
              value={el}
              control={<StyledRadio />}
              label={el}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Row>
  )
}

export default observer(SelectGroup)