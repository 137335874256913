import React, { } from 'react'
import { List, ListSubheader } from '@material-ui/core'
import useStore from 'store'
import { theme, Space } from 'ui'
import Text from 'ui/Text/Text'
import type { Message } from 'utils/models'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import MessageItem from './MessageItem'
import { formatDate } from '../../../utils/date'
import { useTranslation } from 'react-i18next'
import ViewerModal from '../ViewerModal'

const MessageList = styled(List)`
  & li > ul {
    /* padding: 0; */
  }
  &> :first-child {
    /* margin-top: auto; */
    /* use !important to prevent breakage from child margin settings */
}
  /* display:flex;
  flex:1;
  flex-direction:column-reverse;
  overflow-y: scroll; */
  
  display: flex;
    flex-direction: column;
    min-height: 0;
    flex: 1;
    overflow: auto;
    height: 80vh;
`

const SectionHeader = styled(ListSubheader)`
  display: flex;
  align-self:stretch;
  align-items: center;
  justify-content: flex-start;
  /* max-width:50%; */
  /* p {
    display: inline-block;
    opacity: 0.5;
    border-radius: 10px;
    padding: 0.5rem;
  } */
`
const PointerEndOfList = styled.div`
 float: left;
 clear:both;
`
interface MessageSection {
  date: number,
  messages: Message[]
}


const EmptyServiceCall = styled.div`
  display:flex;
  flex:1;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  white-space:pre-line;
`

const ScrollToBottomButton = styled.button<{ showButton: boolean }>`
  display:flex;
  align-items:center;
  justify-content:center;
  box-shadow: #00000080 0 0 0px 0px;
  background-color: #ffffff;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 16px;
  height: 3rem;
  width: 3rem;
  position:fixed;
  bottom:30px;
  z-index:100;
  opacity:0;
  transform: translateY(calc(30px + 48px));
  transition: all .5s ease;
  ${p => p.showButton && `
  opacity: 1;
  transform: translateY(0);
  box-shadow: #00000080 0 0 10px 0px;
  `}
  &:active{
    opacity:0.5;
  }
`

const MessagesFeed: React.FC = () => {
  const { messages, user } = useStore()
  const { t } = useTranslation('common')
  const messagesList = messages.messageFeedSections

  const Wrap = styled.div`
    position: relative;
    padding: 1px 1rem 1px 0;
    display:flex;
    flex:${user.userListTableView ? 2 : 1};
    overflow:hidden;
    background-color:${theme.color.backgroundGray};
  `
  // const [messagesList, setMessagesList] = useState<Array<MessageSection>>([])
  return (
    <Wrap>
      <MessageList subheader={<li />}>
        <Space height={1} />
        {messagesList.map((section: MessageSection) => (
          <li key={`section-${section.date}`} >
            {/* <ul style={{ flex: 1 }}> */}
            <SectionHeader>
              <Text
                style={{ padding: 8, borderRadius: 8, backgroundColor: '#7896d233' }}
                textcolor={theme.color.black + 99}
                size='sm'
                align='left'
                weight='medium'>{section.date ? formatDate(new Date(section.date), t) : ''}</Text>
            </SectionHeader>
            {section.messages?.map((message: Message) => {
              return (
                <React.Fragment key={`item-${message.create_guid || message.id}`}>
                  <div key={`item-${message.create_guid || message.id}`}>
                    <MessageItem key={`item-${message.create_guid || message.id}`} message={message} />
                  </div>
                </React.Fragment>
              )
            })}
            {/* </ul> */}
          </li>
        ))}
      </MessageList>
      <ViewerModal />
    </Wrap>
  )
}

export default observer(MessagesFeed)