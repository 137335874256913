import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { theme, Text, Row, Space } from 'ui'
import { FormControl, FormControlLabel, Radio, RadioGroup, FormLabel, Checkbox, Collapse } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import useStore from 'store'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { FilterCommunity } from 'store/newMessage.store';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const StyledContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    /* justify-content:flex-start; */
`
const SelectComponent = styled.div<{ open: boolean }>`
    opacity:${p => p.open ? 1 : 0};
`
const CommunityList = styled.div`
    display:flex;
    flex-direction:row;
    /* align-items:flex-start; */
    /* width:100%; */
    flex-wrap:wrap;
    /* justify-content:flex-start; */
`
const CheckBoxContainer = styled.div`
    display:flex;
    flex-direction:row;
    width:150px;
    align-items:center;
`
const StyledRadio = styled(Radio)`
  &.MuiRadio-root {
    width: 20px;
    height: 20px;
    color: ${theme.color.communityBlue};
    margin: 6px;
    padding: 0;
  }
`
const StyledCheckBox = styled(Checkbox)`
  &.MuiCheckbox-root {
    color: ${theme.color.communityBlue};
  }
`
const StyledCollapseChevron = styled(KeyboardArrowDownIcon) <{ isCollapsed: boolean }>`
  color:${theme.color.communityBlue};
  cursor:pointer;
  transform:rotate(${p => p.isCollapsed ? '180deg' : '0deg'});
  transition: transform 200ms;
`
const StyledHiddenText = styled(Text)`
  visibility:hidden;
  position:relative;
  direction:ltr;
  min-width:1rem;
  font-variant-numeric:tabular-nums;
`
const StyledAbsoluteText = styled(Text)`
  visibility:visible;
  position:absolute;
  top:0;
  font-variant-numeric:tabular-nums;
`

const SelectCommunityContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

enum SelectMode {
  ALL = 'ALL',
  SELECT = 'SELECT',
}

const distanceCommunityFromBorderList = [
  { 
    value: '0-1',
    color: 'red' 
  },
  { 
    value: '0-2',
    color: 'orange' 
  },
  { 
    value: '4-7',
    color: 'pink' 
  },
  { 
    value: '7-9',
    color: 'green' 
  },
  { 
    value: '9+',
    color: 'blue' 
  },
]

const CheckBoxComponent = ({ text, checked, color = () => null, onChange }: { text: string, checked?: boolean, color?: () => string|null, onChange: (event: any, checked: boolean) => void }) => {
  const c = color();
  return (
    <CheckBoxContainer>
      <StyledCheckBox
        style={c ? { color: c } : { color: '#007aff' }}
        checked={checked}
        onChange={onChange}
      />
      <Text
        textcolor={theme.color.black}
        weight={'normal'}
        size={'sm'}
      >
        {text}
      </Text>
    </CheckBoxContainer>
  )
}


const SelectCommunity: FC<any> = () => {
  const { t } = useTranslation('MessageDialog')
  const { newMessage, user: userStore } = useStore()
  const [areCommunitiesCollapsed, setAreCommunitiesCollapsed] = useState(true)
  const [mode, setMode] = useState<null | SelectMode>(SelectMode.ALL);
  const [selectAll, setSelectAll] = useState(false)
  const [selectedGroups, setSelectedGroup] = useState<string[]>([])
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (SelectMode[event.target.value as SelectMode] === SelectMode.ALL) {
      newMessage.changeStatusAllCommunities(true)
    } else {
      newMessage.changeStatusAllCommunities(false)
    }
    setMode(SelectMode[event.target.value as SelectMode]);
  };
  const handleChangeSelectAll = () => {
    setSelectAll(s => {
      newMessage.changeStatusAllCommunities(!s)
      setSelectedGroup([]);

      if (!s == true) {
        const groups = []
        newMessage.newMessage.communities.map((community: FilterCommunity) => {
          const communityGroup = getCommunityGroup(community.fromKM, community.toKM);
          if (!groups.includes(communityGroup) && communityGroup != '') {
            groups.push(communityGroup);
          }
        })

        setSelectedGroup(groups);
      }

      return !s
    })
  }

  const getCommunityGroup = (fromKM, toKM) => {
    if (fromKM == null && toKM == null) {
      return '';
    } else if(toKM == null) {
      return `${fromKM}+`
    } else {
      return `${fromKM}-${toKM}`
    }
  }
  
  const handleChangeSelectGroup = (groupValue: string) => {
    if (selectedGroups.includes(groupValue)) {
      setSelectedGroup(selectedGroups.filter(gv => gv != groupValue));
      newMessage.newMessage.communities.map((community: FilterCommunity) => {
        const communityGroup = getCommunityGroup(community.fromKM, community.toKM);
        if (communityGroup == groupValue) {
          newMessage.changeCommunityStatus(community.id, false);
        }
      })
    } else {
      setSelectedGroup([...selectedGroups, groupValue]);
      newMessage.newMessage.communities.map((community: FilterCommunity) => {
        const communityGroup = getCommunityGroup(community.fromKM, community.toKM);
        if (communityGroup == groupValue) {
          newMessage.changeCommunityStatus(community.id, true);
        }
      })
    }
  } 

  const isSelectedGroup = (groupValue: string) => {
    return selectedGroups.includes(groupValue);
  }

  const handleCollapse = () => {
    setAreCommunitiesCollapsed(prev => !prev)
  }
  const placeHolderSpace = newMessage.newMessage.communities.length.toString().replaceAll('1', '0')
  let communitiesCounter: number | string = ''

  //fake 3/3 if no label
  communitiesCounter = newMessage.newMessage.label === '' ? `${userStore.communities.length}/${userStore.communities.length}` : `${newMessage.newMessage.communities.filter(c => c.status === true && c.labels.includes(newMessage.newMessage.label)).length}/${newMessage.newMessage.communities.filter(el => el.labels.includes(newMessage.newMessage.label)).length}`

  // if (mode === SelectMode.ALL) {
  //   communitiesCounter = newMessage.newMessage.communities.filter(el => el.labels.includes(newMessage.newMessage.label)).length
  // } else if (mode === SelectMode.SELECT) {
  //   communitiesCounter = `${newMessage.newMessage.communities.filter(c => c.status === true && c.labels.includes(newMessage.newMessage.label)).length}/${newMessage.newMessage.communities.filter(el => el.labels.includes(newMessage.newMessage.label)).length}`
  // }

  return (
    <StyledContainer>
      <Row style={{ alignItems: 'center', justifyContent: 'flex-start', }}>
        <Text textcolor={theme.color.black + 66} weight={'normal'} size={'md'}>
          {t`selectCommunity` + `  : `}
        </Text>
        <StyledHiddenText textcolor={theme.color.black + 66}
          weight={'normal'}
          size={'md'}
        >
          {placeHolderSpace}/{placeHolderSpace}
          <StyledAbsoluteText
            textcolor={theme.color.black + 66}
            weight={'normal'}
            size={'md'}
          >
            {communitiesCounter}
          </StyledAbsoluteText>
        </StyledHiddenText>
        <Space width={1} />
        <FormControl component="fieldset" style={{ flexDirection: 'row' }}>
          <RadioGroup value={mode} onChange={handleChange} row>
            <FormControlLabel
              color={theme.color.communityBlue}
              value={SelectMode.ALL}
              control={<StyledRadio />}
              label={t`allCommunity`}
            />
            <FormControlLabel
              color={theme.color.communityBlue}
              value={SelectMode.SELECT}
              control={<StyledRadio />}
              label={t`selectedCommunity`}
            />
          </RadioGroup>
        </FormControl>
        {mode === SelectMode.SELECT && <StyledCollapseChevron onClick={handleCollapse} isCollapsed={areCommunitiesCollapsed} />}
      </Row>
      <SelectComponent open={mode === SelectMode.SELECT}>
        <Space />
        <Collapse in={areCommunitiesCollapsed && (mode === SelectMode.SELECT)} collapsedHeight={0}>
          <CheckBoxComponent
            checked={selectAll}
            text={t`selectAll`}
            onChange={handleChangeSelectAll}
          />
          {[1,2,3,4, 5].includes(userStore.userInfo?.id) && <SelectCommunityContainer>
            {distanceCommunityFromBorderList.map(item => (
              <CheckBoxComponent
                key={item.value}
                checked={isSelectedGroup(item.value)}
                color={() => item.color}
                text={item.value}
                onChange={() => {handleChangeSelectGroup(item.value)}}
              />
            ))}
          </SelectCommunityContainer>}
          <Space />      
          <CommunityList>
            {
              newMessage.newMessage.communities.map((community: FilterCommunity) =>
                community.labels.includes(newMessage.newMessage.label) ? (

                  <CheckBoxComponent
                    onChange={(_event, checked) => newMessage.changeCommunityStatus(community.id, checked)}
                    checked={community.status}
                    text={community.name}
                    color={() => { 
                      if (!community.status) {
                        return null;    
                      }

                      const group = getCommunityGroup(community.fromKM, community.toKM)

                      if (!selectedGroups.includes(group)) {
                        return null;
                      }

                      const foundGroup =  distanceCommunityFromBorderList.find((element) => element.value == group);

                      if (foundGroup === undefined) {
                        return null;
                      }

                      return foundGroup.color;
                    }}
                  />) : null
              )
            }
          </CommunityList>
        </Collapse>
        
      </SelectComponent>
    </StyledContainer>
  )
}

export default observer(SelectCommunity)


