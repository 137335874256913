export const storageKeys = {
  currentCommunityId: '@mekome_go_current_community_id',
  currentGroupId: '@mekome_go_current_group_id',
  currentTab: '@mekome_go_current_tab',
  LANG:'@MEKOME_go_ADMIN_LANG',
  user: '@mekome_go_user',
  is_auth: '@mekome_go_is_auth',
  token: '@mekome_go_token'
}

const veryLocalStorage = {
  set(key: string, value: any): void {
    const toSave = JSON.stringify(value)
    localStorage.setItem(key, toSave)
  },
  get(key: string): any {
    try {
      const data = localStorage.getItem(key)
      if (!data) return false
      const json = JSON.parse(data)
      return json
    } catch {
      return false
    }
  },
  remove(key: string): void {
    localStorage.removeItem(key)
  },
  clear(): void {
    Object.values(storageKeys).forEach(key => veryLocalStorage.remove(key))
  }
}

export default veryLocalStorage
