import React, { FC } from 'react'
import { CircularProgress, Fade, IconButton, LinearProgress, Table, TableCell, TableRow } from '@material-ui/core'
import { Row, Space, Text, theme } from 'ui'
import type { Message } from 'utils/models'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import useStore from 'store'
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns'
import { CommunityResponse } from 'store/messages.store'
import { useTranslation } from 'react-i18next'
import Loading from 'core/Loading'
import { useCountUp } from 'react-countup'
import Progress from '../Progress'
import isMobile from 'utils/isMobile'

const Separator = styled.div`
  height: 1px;
  background-color: ${theme.color.gray};
`
const StyledTableCell = styled(TableCell) <{ grow?: boolean }>`
  width:${p => p.grow ? '100%' : 'auto'};
  padding:8px;
`
const StyledPopoverContainer = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  width: 100%;
  z-index:10;
  /* padding-bottom: 16px; */
  border-radius:10px;
  pointer-events:${p => p.isOpen ? 'auto' : 'none'};
`
const StyledCustomPopover = styled.div<{ open: boolean }>`
  @media (max-width: 768px) {
    margin-right: -300px;
}
  overflow:hidden;
  background-color: white;
  box-shadow: 0 2px 8px 0 ${theme.color.shadow};
  border-radius:10px;
  border:2px solid ${theme.color.blue};
  /* visibility:${p => p.open ? 'visible' : 'hidden'}; */
`
const Header = styled.div`
  padding:1rem 1rem 0 1rem;
  display:flex;
  justify-content:space-between;
  flex-direction:row;
  align-items: flex-start;
`
const Piramide = styled.div`
  width: 0; 
  height: 0; 
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  position:absolute;
  right:-12px;
  top:17px;
  border-left: 12px solid ${theme.color.blue};
`
const PiramideIn = styled.div`
  width: 0; 
  height: 0; 
  top:-12px;
  left:-3px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  position:relative;
  border-left: 12px solid  ${theme.color.white};
`
const StyledResponseContainer = styled(Row)`
  overflow:auto;
  max-height:50vh;
  padding: 0 1rem 1rem 1rem;
  align-items:flex-start;
`

const PopoverResponse: React.FC<{ communityResponse: CommunityResponse }> = ({ communityResponse }) => {
  const { countUp: totalCountUp, update: totalUpdate } = useCountUp({
    start: communityResponse.total,
    end: communityResponse.total,
    delay: 0,
    duration: 2,
  });
  const { countUp: receivedCountUp, update: receivedUpdate } = useCountUp({
    start: communityResponse.received,
    end: communityResponse.received,
    delay: 0,
    duration: 2,
  });
  const [total, setTotal] = React.useState(communityResponse.total || 1)
  const [received, setReceived] = React.useState(communityResponse.received)
  React.useEffect(() => {
    setTotal(communityResponse.total)
    setReceived(communityResponse.received)
    totalUpdate(communityResponse.total)
    receivedUpdate(communityResponse.received)
  }, [communityResponse.total, communityResponse.received])
  return (
    <TableRow style={{ padding: 8, borderBottomWidth: 1, borderBottomColor: theme.color.gray, borderBottomStyle: 'solid', }}>
      <StyledTableCell>
        <Row>
          <Text textcolor={theme.color.blandGray}>{communityResponse.community_name}</Text>
          <Space width={1} />
          <div style={{ width: 1, height: '1.5rem', borderLeftStyle: 'solid', borderLeftWidth: 1, borderLeftColor: theme.color.gray }} />
        </Row>
        {/* <Text textcolor={theme.color.blandGray}>|</Text> */}
      </StyledTableCell>
      {/* <Text textcolor={theme.color.blandGray}>{communityResponse.community.name}</Text> */}
      <StyledTableCell>
        <Row justify='start'>
          <Text textcolor={theme.color.darkGreen} weight='bold'>קיבלו</Text>
          <Space width={1} />
          <Row>
            <Text >{totalCountUp}/</Text>
            <Text textcolor={theme.color.darkGreen} weight='bold'>{receivedCountUp}</Text>
          </Row>
        </Row>
      </StyledTableCell>
      <StyledTableCell grow>
        <Row>
          <Progress denumerator={(total)} numerator={(received)} />
          <Space width={1} />
          <Text style={{ minWidth: '4rem' }} textcolor={theme.color.darkGray}>{((received / (total)) * 100).toFixed(2)}%</Text>
        </Row>
      </StyledTableCell>
    </TableRow>
  )
}
interface CustomPopoverProps {
  message: Message
  open: boolean,
  responses: CommunityResponse[]
}

const MessageItem: FC<CustomPopoverProps> = ({ message, open, responses: communityResponses = [] }) => {
  const { messages } = useStore()
  const { t } = useTranslation('messageDetails')
  const [isLoading, setIsLoading] = React.useState(true)
  const [responses, setResponses] = React.useState<CommunityResponse[]>(communityResponses)
  const { countUp: total, update: totalUpdate } = useCountUp({
    start: responses.length || 0,
    end: responses.length || 0,
    delay: 0,
    duration: 2,
  });

  // const responses = messages.messageResponses.get(message.id) || []
  // const open = messages.activeTooltip === userMessage.message.id
  const handleClose = () => {
    messages.setActiveTooltip(null)
  }
  React.useEffect(() => {
    if (open) {
      messages.getMessageDetails(message.id).then(el => {
        setResponses(el)
        setIsLoading(false)
        const totals = el.reduce((a, b) => {
          a.total += b.total
          a.received += b.received
          return a
        }, { total: 0, received: 0 })
        totalUpdate(el.length)
        messages.updateMessageDetails(message, totals.total, totals.received)
      })
    }
  }, [open])
  React.useEffect(() => {
    if (communityResponses != responses) {
      setResponses(communityResponses)
      totalUpdate(communityResponses.length)
    }
  }, [communityResponses])
  return (
    <StyledPopoverContainer isOpen={open}>
      <Fade in={open}>
        <StyledCustomPopover open={open}>
          <Header>
            <div>
              <Row justify='start'>
                <Text weight='bold'>{message.labels?.[0] || ''}</Text>
                <Space width={0.5} />
                <Text weight='bold'>|</Text>
                <Space width={0.5} />
                <Text>{t`sent`} ל {isLoading ? <CircularProgress size={'1rem'} /> : total} {t`settlements`}</Text>
              </Row>
              <Row>
                <Text textcolor={theme.color.blandGray} size='sm'>{t`sent`}: {format(message.created, 'HH:mm')}</Text>
                <Space width={0.5} />
                <Text weight='bold' textcolor={theme.color.blandGray} size='sm'>|</Text>
                <Space width={0.5} />
                <Text textcolor={theme.color.blandGray}>{t`messagesTable:expiry`}:</Text>
                <Space width={0.5} />
                <Text textcolor={theme.color.blandGray} style={{ direction: 'ltr' }}>{format(message.expiry || new Date(), 'dd / MM / yy')}</Text>
              </Row>
            </div>
            {isMobile() ? null
              : <Piramide >
                <PiramideIn />
              </Piramide>}
            <IconButton onClick={handleClose} size='small'>
              <CloseIcon />
            </IconButton>
          </Header>
          <Separator />
          <StyledResponseContainer>
            <div style={{ borderBottomWidth: 1, borderBottomColor: theme.color.gray, borderBottomStyle: 'solid' }}></div>
            {isLoading ? (<LinearProgress style={{ marginTop: '1em', width: '100%' }} />) : (
              <Table >
                {responses.map(el => <PopoverResponse communityResponse={el} />)}
              </Table>
            )}
          </StyledResponseContainer>
        </StyledCustomPopover>
      </Fade>
    </StyledPopoverContainer>
  )
}

export default observer(MessageItem)
