import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { AppBar, Toolbar, Tooltip } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import logoIcon from 'assets/icons/Logo.svg'
import userIcon from 'assets/icons/UserLogo.svg'
import { Space, Image, theme, Row, Button } from 'ui'
import useStore from 'store'
import { useTranslation } from 'react-i18next'
import packageJson from '../../../package.json'
import AddIcon from '@material-ui/icons/Add';
import WhatsappIcon from 'assets/icons/whatsapp.svg'
import ContactPhone from 'assets/icons/contactPhone.svg'

const StyledAppBar = styled(AppBar)`
  box-shadow: none;
  background-color: var(--dark-back);
  color: var(--white);
`
const StyledTopbar = styled(Toolbar)`
  display: flex;
  justify-content: center;
  color: var(--white);
`
const UserIcon = styled(Image)`
  padding: 0 20px 0 20px;
`
const UserText = styled.div`
 font-size: ${theme.fontSize.small};
`
const Logo = styled.img`
`
const StyledLogout = styled.div`
  display:inline-flex;
  border-bottom: 1px solid white;
  cursor:pointer;
`
const NewMessageButton = styled(Button)`
  background-color:${theme.color.blandGray};
  color:${theme.color.black};
  align-items:center;
  justify-content:center;
`
const Whatsapp = () => {
  return (
    <img width={16} height={16}  src={WhatsappIcon} style={{ margin: '0px 3px 0px 7px' }}  />
  )
}

const Phone = () => {
  return (
    <img width={16} height={16} src={ContactPhone}  style={{ margin: '0px 3px 0px 7px', color: 'white' }} />
  )
}

const Appbar: FC = () => {
  const { t } = useTranslation('app')

  return (
    <StyledAppBar id='app-bar' position='static'>
      <StyledTopbar>
        לתמיכה טכנית בשעות הפעילות יש להתקשר ל <span style={{ marginRight: '3px' }}><a href="tel:+97249001970">04-9001970</a><Phone/></span> או לשלוח הודעת וואצאפ למספר  <span style={{ marginRight: '3px' }}><a href="https://api.whatsapp.com/send?phone=972587198999">058-7198999</a><Whatsapp /></span>
      </StyledTopbar>
    </StyledAppBar>
  )
}

export default observer(Appbar)
