import React, { FC } from 'react'
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'
import type { CircularProgressProps } from '@material-ui/core'

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`

export interface CircularProgressLabelProps extends CircularProgressProps {
  enableText?: boolean;
  top?: number;
  textSize?: number | string;
  textColor?: string;
  text?: string;
}

const Spinner: FC<CircularProgressLabelProps> = ({ ...props }) => <SpinnerContainer>
   {props.enableText && (<span style={{color: props.textColor, position: 'absolute', top: `${props.top}px`, alignContent: 'center', fontSize: `${props.textSize}px`}}>{props.text}</span>)}
  <CircularProgress {...props} />
  </SpinnerContainer>

export default Spinner
