import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { inject, Observer, observer } from 'mobx-react';
import { autorun, isObservable, toJS } from 'mobx';
import useStore from 'store';


class Notifier extends Component {
    displayed = [];

    storeDisplayed = (id) => {
        this.displayed = [...this.displayed, id];
    };

    componentDidMount() {
        autorun(() => {
            const { notifications = [], latestNotification } = this.props.store;
            // console.log('notifier props are', this.props)
            // console.log('latestNotificationprops are', JSON.parse(JSON.stringify(latestNotification)))
            // console.log('notifications are sassa', JSON.parse(JSON.stringify(notifications)))
            // console.log('dispalesdare sassa', JSON.parse(JSON.stringify(this.displayed)))
            // console.log('isobservabel', isObservable(toJS(latestNotification)))
            if (latestNotification) {
                // console.log('enqueue', latestNotification)
                // console.log('enqueue2', { ...latestNotification })
                this.props.enqueueSnackbar(latestNotification.message, latestNotification.options);
            }
            /*
            notifications.forEach((notification) => {
                // Do nothing if snackbar is already displayed
                if (this.displayed.includes(notification.key)) return;
                // Display snackbar using notistack
                this.props.enqueueSnackbar(notification.message, notification.options);
                // Keep track of snackbars that we've displayed
                this.storeDisplayed(notification.key);
                // Dispatch action to remove snackbar from mobx store
                this.props.store.removeSnackbar(notification.key);
            });
            */
        });
        autorun(() => {
            const { latestRemove } = this.props.store;
            if (latestRemove) {
                this.props.closeSnackbar(latestRemove);
            }
        })
    }

    render() {
        return null;
    }
}
const NotifierComponent = observer(Notifier)
const Wrap = observer((props) => {
    const { snackBar } = useStore()
    const { enqueueSnackbar, removeSnackbar } = snackBar
    return <NotifierComponent
        store={snackBar}
        {...props}
    />
})
export default withSnackbar((Wrap));
