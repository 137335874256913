import React from 'react'
import { theme } from 'ui'
import { observer } from 'mobx-react'
import styled from 'styled-components'

const StyledProgress = styled.div<{ numerator: number, denumerator: number }>`
    width:${p => ((p.numerator / p.denumerator) * 100)}%;
    background-color:${theme.color.darkGreen};
    height:100%;
    border-radius:5px;
    transition: width 2s;
`
const Progress: React.FC<{ numerator: number, denumerator: number }> = ({ numerator, denumerator = 1 }) => {
    return (
        <div style={{ flex: 1, height: 10, backgroundColor: theme.color.gray, borderRadius: '5px', overflow: 'hidden' }}>
            <StyledProgress numerator={numerator} denumerator={denumerator || 1} />
        </div>
    )
}
export default observer(Progress)