/* eslint-disable func-names */
import { observable, action, computed, IObservableArray, runInAction } from 'mobx'
import { OptionsObject } from 'notistack'
import { Close } from '@material-ui/icons'
import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import { theme } from 'ui'

const StyledClose = styled(Close)`
  cursor: pointer;
  color:${theme.color.white};
`
type SnackbarVariant = "error" | "default" | "success" | "warning" | "info" | undefined
class SnackStore {

  constructor() {
  }
  @observable notifications: IObservableArray<any> = observable([])
  @observable latestNotification: any = null
  @observable latestRemove: any = null
  @action enqueueSnackbar(message: string, options: OptionsObject) {
    const notification = {
      message: message,
      options: {
        key: new Date().getTime() + Math.random(),
        ...options
      }
    }
    // this.notifications.push(notification);
    this.latestNotification = notification
  }
  @action removeSnackbar(note: string) {
    this.notifications.remove(note);
  }
  @action showError(message: string, variant: SnackbarVariant = 'error') {
    this.enqueueSnackbar(message, {
      variant: variant,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      action: (key) => (
        <IconButton size='small' onClick={() => runInAction(() => this.latestRemove = key)} >
          <StyledClose />
        </IconButton>
      ),
    })
    return []
  }

}

const snackStore = new SnackStore()
export default snackStore
