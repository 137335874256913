import React, { useState } from 'react';
import { Page, pdfjs, Outline, Document } from "react-pdf";
import styled from 'styled-components'
import { ChevronRight, ChevronLeft, Close } from '@material-ui/icons'
import { IconButton, Space, Image, Spinner } from 'ui'


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;


const PageControls = styled.div<{ show: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    box-shadow: 0 10px 20px 0 rgba(16, 36, 94, 0.2);
    border-radius: 4px;
    padding:5px;
    position:absolute;
    bottom: 0;
    width:100%;
  `
const PDFDocumentWrapper = styled.div`
canvas {
  width: 100% !important;
  height: auto !important;
}
`;

function PdfViewer({ file, onClick, disablePages = true }: { file: any, onClick?: any, disablePages?: boolean, pdfHeight?: number,pdfWidth?:number }) {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [showControls, setShowControls] = useState<boolean>(false);
  const [finishLoading, setfinishLoading] = useState(false)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setfinishLoading(true)
  }

  const forwardPage = () => {
    const toPage = pageNumber + 1
    if (numPages && toPage <= numPages) {
      setPageNumber(toPage)
    }
  }
  const backPage = () => {
    const toPage = pageNumber - 1
    if (toPage > 0) {
      setPageNumber(toPage)
    }
  }
  return (
    <>
      {/* <PDFDocumentWrapper> */}
         <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
          loading={() => <div style={{ flex: 1}}><Spinner /></div>}
        >
          
          <Page
            loading={()=><div style={{flex:1}}><Spinner /></div>}
            pageNumber={pageNumber}
          />
        </Document>
        {/* </PDFDocumentWrapper> */}
      {!disablePages && finishLoading && <PageControls show={showControls}>
        <IconButton style={{ backgroundColor: 'transparent', width: 0, margin: 0, marginLeft: 10 }} onClick={forwardPage}>
          <ChevronRight />
        </IconButton>
        <span>{`${numPages} / ${pageNumber}`}</span>
        <IconButton style={{
          backgroundColor: 'transparent'
          , width: 0
          , margin: 0
          , marginRight: 10
        }} onClick={backPage} >
          <ChevronLeft />
        </IconButton>
      </PageControls>} 
    </>
  )
}

export default PdfViewer
