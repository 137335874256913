import React from 'react'

const icons = {}
export default icons

import Default from './groupIcons/default.svg'
icons['default'] = Default

import Account from './groupIcons/account.svg'
icons['account'] = Account

import Arnakd from './groupIcons/arnakd.svg'
icons['arnakd'] = Arnakd

import Avokado from './groupIcons/avokado.svg'
icons['avokado'] = Avokado

import Barmitzva from './groupIcons/barmitzva.svg'
icons['barmitzva'] = Barmitzva

import Budget from './groupIcons/budget.svg'
icons['budget'] = Budget

import Building from './groupIcons/building.svg'
icons['building'] = Building

import Car from './groupIcons/car.svg'
icons['car'] = Car

import Catering from './groupIcons/catering.svg'
icons['catering'] = Catering

import Clinic from './groupIcons/clinic.svg'
icons['clinic'] = Clinic

import Corona from './groupIcons/corona.svg'
icons['corona'] = Corona

import Debit from './groupIcons/debit.svg'
icons['debit'] = Debit

import DentalCare from './groupIcons/dental_care.svg'
icons['dental_care'] = DentalCare

import DinningRoom from './groupIcons/dinning_room.svg'
icons['dinning_room'] = DinningRoom

import Drags from './groupIcons/drags.svg'
icons['drags'] = Drags

import Elders from './groupIcons/elders.svg'
icons['elders'] = Elders

import Electricity from './groupIcons/electricity.svg'
icons['electricity'] = Electricity

import Factory from './groupIcons/factory.svg'
icons['factory'] = Factory

import Fire from './groupIcons/fire.svg'
icons['fire'] = Fire

import Football from './groupIcons/football.svg'
icons['football'] = Football

import Gadash from './groupIcons/gadash.svg'
icons['gadash'] = Gadash

import Gardening from './groupIcons/gardening.svg'
icons['gardening'] = Gardening

import GlobalMessages from './groupIcons/global_messages.svg'
icons['global_messages'] = GlobalMessages

import Grapes from './groupIcons/grapes.svg'
icons['grapes'] = Grapes

import Gym from './groupIcons/gym.svg'
icons['gym'] = Gym

import Hairdresser from './groupIcons/hairdresser.svg'
icons['hairdresser'] = Hairdresser

import Hosting from './groupIcons/hosting.svg'
icons['hosting'] = Hosting

import Hostingroom from './groupIcons/hostingroom.svg'
icons['hostingroom'] = Hostingroom

import Internet from './groupIcons/internet.svg'
icons['internet'] = Internet

import Kalfi from './groupIcons/kalfi.svg'
icons['kalfi'] = Kalfi

import Kindergarden from './groupIcons/kindergarden.svg'
icons['kindergarden'] = Kindergarden

import Kitchen from './groupIcons/kitchen.svg'
icons['kitchen'] = Kitchen

import Laundry from './groupIcons/laundry.svg'
icons['laundry'] = Laundry

import Library from './groupIcons/library.svg'
icons['library'] = Library

import Mail from './groupIcons/mail.svg'
icons['mail'] = Mail

import Marcolit from './groupIcons/marcolit.svg'
icons['marcolit'] = Marcolit

import Melonit from './groupIcons/melonit.svg'
icons['melonit'] = Melonit

import Messages from './groupIcons/messages.svg'
icons['messages'] = Messages

import Mizug from './groupIcons/mizug.svg'
icons['mizug'] = Mizug

import Pardes from './groupIcons/pardes.svg'
icons['pardes'] = Pardes

import Pension from './groupIcons/pension.svg'
icons['pension'] = Pension

import Phone from './groupIcons/phone.svg'
icons['phone'] = Phone

import Pool from './groupIcons/pool.svg'
icons['pool'] = Pool

import Restaurant from './groupIcons/restaurant.svg'
icons['restaurant'] = Restaurant

import Savuot from './groupIcons/savuot.svg'
icons['savuot'] = Savuot

import Scool from './groupIcons/scool.svg'
icons['scool'] = Scool

import Security from './groupIcons/security.svg'
icons['security'] = Security

import Shows from './groupIcons/shows.svg'
icons['shows'] = Shows

import Sinagogue from './groupIcons/sinagogue.svg'
icons['sinagogue'] = Sinagogue

import Slika from './groupIcons/slika.svg'
icons['slika'] = Slika

import Sochrim from './groupIcons/sochrim.svg'
icons['sochrim'] = Sochrim

import Soldiers from './groupIcons/soldiers.svg'
icons['soldiers'] = Soldiers

import Spa from './groupIcons/spa.svg'
icons['spa'] = Spa

import Store from './groupIcons/store.svg'
icons['store'] = Store

import Students from './groupIcons/students.svg'
icons['students'] = Students

import Support from './groupIcons/support.svg'
icons['support'] = Support

import SystemUpdates from './groupIcons/system_updates.svg'
icons['system_updates'] = SystemUpdates

import Tzachy from './groupIcons/tzachy.svg'
icons['tzachy'] = Tzachy

import Water from './groupIcons/water.svg'
icons['water'] = Water

import Women from './groupIcons/women.svg'
icons['women'] = Women

import Coffe from './groupIcons/coffe.svg'
icons['coffe'] = Coffe

import Birds from './groupIcons/birds.svg'
icons['birds'] = Birds

import Cows from './groupIcons/cows.svg'
icons['cows'] = Cows

import Farm from './groupIcons/farm.svg'
icons['farm'] = Farm

import Garage from './groupIcons/garage.svg'
icons['garage'] = Garage

import SafeHouse from './groupIcons/safeHouse.svg'
icons['safeHouse'] = SafeHouse

import SafeHouse2 from './groupIcons/safeHouse2.svg'
icons['safeHouse2'] = SafeHouse2

import Flowers from './groupIcons/flowers.svg'
icons['flowers'] = Flowers

import LawnMower from './groupIcons/LawnMower.svg'
icons['LawnMower'] = LawnMower

import Bakery from './groupIcons/bakery.svg'
icons['bakery'] = Bakery

import Bakery2 from './groupIcons/bakery2.svg'
icons['bakery2'] = Bakery2

import Pastry from './groupIcons/pastry.svg'
icons['pastry'] = Pastry

import Bank from './groupIcons/bank.svg'
icons['bank'] = Bank

import Insurance from './groupIcons/insurance.svg'
icons['insurance'] = Insurance

import Chess from './groupIcons/chess.svg'
icons['chess'] = Chess

import Plays from './groupIcons/Plays.svg'
icons['Plays'] = Plays

import Concerts from './groupIcons/concerts.svg'
icons['concerts'] = Concerts

import Music from './groupIcons/music.svg'
icons['music'] = Music

import Corona2 from './groupIcons/corona2.svg'
icons['corona2'] = Corona2

import BloodDonation from './groupIcons/bloodDonation.svg'
icons['bloodDonation'] = BloodDonation

import Veterinarian from './groupIcons/veterinarian.svg'
icons['veterinarian'] = Veterinarian

import Fifty from './groupIcons/fifty.svg'
icons['fifty'] = Fifty

import Forty from './groupIcons/forty.svg'
icons['forty'] = Forty

import Thirty from './groupIcons/thirty.svg'
icons['thirty'] = Thirty

import Twenty from './groupIcons/twenty.svg'
icons['twenty'] = Twenty

import Welcome from './groupIcons/welcome.svg'
icons['welcome'] = Welcome

import Spokes from './groupIcons/spokes.svg'
icons['spokes'] = Spokes

import HumanResources from './groupIcons/humanResources.svg'
icons['humanResources'] = HumanResources

import Agriculturaleadership from './groupIcons/agriculturaleadership.svg'
icons['agriculturaleadership'] = Agriculturaleadership

import archives from './groupIcons/archives.svg'
icons['archives'] = archives

import BasketBall from './groupIcons/basketBall.svg'
icons['basketBall'] = BasketBall

import Bicycle from './groupIcons/bicycle.svg'
icons['bicycle'] = Bicycle

import Netball from './groupIcons/netball.svg'
icons['netball'] = Netball

import Power from './groupIcons/power.svg'
icons['power'] = Power

import Run from './groupIcons/run.svg'
icons['run'] = Run

import Yoga from './groupIcons/yoga.svg'
icons['yoga'] = Yoga

import Swimming from './groupIcons/swimming.svg'
icons['swimming'] = Swimming

import HolocaustDay from './groupIcons/holocaustDay.svg'
icons['holocaustDay'] = HolocaustDay

import MemorialDay from './groupIcons/memorialDay.svg'
icons['memorialDay'] = MemorialDay

import Sabbath from './groupIcons/Sabbath.svg'
icons['Sabbath'] = Sabbath

import Wedding from './groupIcons/wedding.svg'
icons['wedding'] = Wedding

import HighSchool from './groupIcons/highSchool.svg'
icons['highSchool'] = HighSchool

import Infancy from './groupIcons/infancy.svg'
icons['infancy'] = Infancy

import Hamal from './groupIcons/hamal.svg'
icons['hamal'] = Hamal

import Rescue from './groupIcons/rescue.svg'
icons['rescue'] = Rescue

import Ambulance from './groupIcons/ambulance.svg'
icons['ambulance'] = Ambulance

import Carpentry from './groupIcons/carpentry.svg'
icons['carpentry'] = Carpentry

import HomeDriver from './groupIcons/homeDriver.svg'
icons['homeDriver'] = HomeDriver

import Motorcycle from './groupIcons/motorcycle.svg'
icons['motorcycle'] = Motorcycle

import Yesodi from './groupIcons/yesodi.svg'
icons['yesodi'] = Yesodi

import Rocket from './groupIcons/rocket.svg'
icons['rocket'] = Rocket

import Users from './groupIcons/users.svg'
icons['users'] = Users
