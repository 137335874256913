import { action, observable, toJS, IObservableArray, makeObservable, reaction } from 'mobx'
import { StrategyType, MessageOp, file, Community, FilesType, StatusType } from '../utils/types'
import { OperationType } from '../utils/operation'
import { newGuid } from '../utils/generates'
import api from '../service/api'
import userStore from './user.store'
import { addDays } from 'date-fns'
import imageCompression from 'browser-image-compression'
import { Message } from 'utils/models'
import messages, { MessageDetails } from './messages.store'
import { MessageTwoTone } from '@material-ui/icons'
import * as moment from 'moment'


const IMAGE_MAX_HEIGHT_AND_WIDTH = 800
const MAX_FILE_SIZE_MB = 2

export enum selectGroup {
  CULTURE = 'CULTURE',
  SECURITY = 'SECURITY',
  GENERAL = 'GENERAL',
}
enum groupNumbers {
  CULTURE = 5273,
  SECURITY = 5271,
  GENERAL = 5272,
}
export interface FilterCommunity extends Community {
  status: boolean
}
interface goNewMessage {
  guid: string
  senderId: number
  strategyType: StrategyType
  label: string
  payload: string
  files: file[] | null
  expiry: Date
  schedule: Date | null
  communities: IObservableArray<FilterCommunity>
  id: number
}
const IsJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
class NewMessageStore {

  constructor() {
    makeObservable(this, {
      messageDialogOpen: observable,
      currentMessage: observable,
      openMessageDialog: action,
      closeMessageDialog: action,
      newMessage: observable,
      updateMessagePayload: action,
      updateGroupSelect: action,
      updateExpiryDate: action,
      updateScheduleDate: action,
      setFiles: action,
      clearFiles: action,
      changeCommunityStatus: action,
      changeStatusAllCommunities: action,
      addNewMessageToFeed: action,
    })

    //close dialog incase auth triggered logout
    reaction(() => (userStore.isAuth), (isAuth: boolean) => {
      if (!isAuth) {
        this.closeMessageDialog()
      }
    })
  }

  emptyNewMessage: goNewMessage = {
    guid: newGuid(),
    senderId: userStore.user?.user_id,
    strategyType: StrategyType.text,
    payload: '',
    files: null,
    expiry: addDays(new Date(), 2),
    schedule: null,
    communities: observable([]),
    id: 0,
    label: ''
  }
  currentMessage: Message | null = null
  messageDialogOpen: boolean = false
  newMessage: goNewMessage = this.emptyNewMessage;
  selectAll: boolean = true //not observable

  getExpiryDateByApi =  async () => {
    const res = await api.getCuttentUtc()
    
    if (!res) {
      return new Date();
    }

    return moment.utc(res).utc().toDate()
  }

  openMessageDialog: () => void = async () => {
    const extExpiryDate = await this.getExpiryDateByApi();

    this.newMessage = this.emptyNewMessage
    this.newMessage.expiry = addDays(extExpiryDate, 2),
    this.selectAll = true
    userStore.communitiesLoading = true
    this.messageDialogOpen = true
    await userStore.initCommunities()
    this.setCommunities()
    this.newMessage.guid = newGuid()
  }
  closeMessageDialog: () => void = () => {
    this.messageDialogOpen = false
  }
  setMessageLabel = (label: string) => {
    this.newMessage.label = label
  }
  updateMessagePayload = (text: string) => {
    this.newMessage.payload = text
  };
  updateGroupSelect = (groupEnum: string | null) => {
    if (groupEnum) {
      this.newMessage.label = groupEnum
      //uncheck communities that are not part of that 'group'
      if (!this.selectAll) {
        this.newMessage.communities.forEach(el => {
          if (!el.labels.includes(groupEnum)) {
            el.status = false
          }
        })
      }
    }
  };
  setFiles = (file: any, type: FilesType, local: boolean) => {
    this.newMessage.files = [{ url: file, type: type, local: local }]
  };
  clearFiles = () => {
    this.newMessage.files = null
  };
  updateExpiryDate = (date: Date) => {
    this.newMessage.expiry = date
  };
  updateScheduleDate = (date: Date | null) => {
    this.newMessage.schedule = date
  };
  setCommunities = () => {
    this.newMessage.communities.replace(observable(userStore.communities.map(el => ({ ...el, status: true }))))
  };
  changeCommunityStatus = (id: number, status: boolean) => {
    const foundCommunity = this.newMessage.communities.find(el => el.id === id)
    if (foundCommunity) {
      foundCommunity.status = status
    }

  }
  changeStatusAllCommunities = (status: boolean) => {
    this.selectAll = status
    this.newMessage.communities.forEach((community: FilterCommunity) => {
      community.status = status
    })
  };


  createMessage = () => {
    const updatedMessage = this.newMessage && this.newMessage.id != 0
    if (updatedMessage) {
      this.updateMessageInFeed()
    } else {
      this.addNewMessageToFeed()
    }
  };


  addNewMessageToFeed = async () => {
    // if (!this.newMessage.group) {
    //   return
    // }
    this.closeMessageDialog()
    const allSelected = this.newMessage.communities.filter(el => el.status).length === userStore.communities.length
    const communities = allSelected ? [] : (this.newMessage?.communities?.filter(el => el.status).map(el => el.id) || [])
    try {
      let messagePayload: string = this.newMessage.payload
      if (this.newMessage.files) {
        messagePayload = JSON.stringify({ text: this.newMessage.payload, files: [{ fileUrl: this.newMessage.files[0].type === 'IMAGE' ? URL.createObjectURL(this.newMessage.files[0].url) : { url: URL.createObjectURL(this.newMessage.files[0].url) }, type: this.newMessage.files[0].type, }] })
        this.newMessage.strategyType = StrategyType.media
      }
      const messageOp: MessageOp = {
        op: OperationType.CREATE_MESSAGE,
        sender: userStore.user.user_id,
        type: this.newMessage.strategyType,
        payload: this.newMessage.payload,
        expiry: this.newMessage.expiry?.toISOString(),
        guid: this.newMessage.guid,
        id: 1,
        communities: communities,
        allCommunities: allSelected ? 1 : undefined,
        labels: [this.newMessage.label]
      }
      let blob = null
      if (this.newMessage.files) {
        if (this.newMessage.files[0]?.type === FilesType.IMAGE) {
          blob = await imageCompression(this.newMessage.files[0].url, { maxSizeMB: MAX_FILE_SIZE_MB, maxWidthOrHeight: IMAGE_MAX_HEIGHT_AND_WIDTH })
          messageOp.files = [{ type: FilesType.IMAGE }]
          messageOp.type = StrategyType.media
        }
        else if (this.newMessage.files[0]?.type === FilesType.PDF) {
          blob = this.newMessage.files[0].url
          messageOp.type = StrategyType.media
          messageOp.files = [{ type: FilesType.PDF }]
        }
      }
      this.newMessage.payload = messagePayload
      messages.updateMessage(this.createMessageFromNewMessage(StatusType.OUTGOING, communities))
      const createdId = await api.createMessage([messageOp], blob)
      if (createdId) {
        this.newMessage.id = createdId
        const messageDetailsRes = await api.getMessageDetails(createdId)
        await userStore.initInfo()
        if (messageDetailsRes.success) {
          const msgDetails = messageDetailsRes.data?.[0] as MessageDetails | undefined
          // messages.updateMessage(this.createMessageFromNewMessage(StatusType.SENT, message.communities, msgDetails.received, msgDetails.total))
          messages.updateMessage(this.createMessageFromNewMessage(StatusType.SENT, communities, msgDetails?.received || 0, msgDetails?.total || 0))
        }
      }
      else {
        throw 'fail'
      }
      this.clearFiles()
    } catch (e) {
      console.log('error', e)
      messages.updateMessage(this.createMessageFromNewMessage(StatusType.FAILED, communities))
    }
  }
  updateMessageInFeed = async () => {

  }
  sendAgain = async (message: Message) => {
    let blob: null | Blob = null
    let file: null | File = null
    let messageText: string = ''
    let filesType: null | string = null

    if (message.msg_strategy === 'MEDIA' && IsJsonString(message.payload)) {

      const parseString = JSON.parse(message.payload)

      messageText = parseString.text
      filesType = parseString.files[0].type
      file = await fetch(parseString.files[0].fileUrl).then(r => r.blob()).then(blobFile => new File([blobFile], filesType === "IMAGE" ? 'newImage.jpeg' : 'newDoc.pdf', { type: blobFile.type }))


      if (filesType === 'IMAGE') {
        blob = await imageCompression(file, { maxSizeMB: MAX_FILE_SIZE_MB, maxWidthOrHeight: IMAGE_MAX_HEIGHT_AND_WIDTH })
      }
      else if (filesType === 'PDF') {
        blob = file
      }
    } else {
      messageText = message.payload
    }
    try {
      const messageOp: MessageOp = {
        op: OperationType.CREATE_MESSAGE,
        sender: userStore.user.user_id,
        type: message.msg_strategy,
        payload: messageText,
        expiry: message.expiry?.toISOString(),
        guid: message.create_guid,
        id: message.id,
        communities: message.communities || [],
        allCommunities: message.allSelected,
        labels: [this.newMessage.label]
      }
      if (this.newMessage.files) {
        if (this.newMessage.files[0]?.type === FilesType.IMAGE) {
          blob = await imageCompression(this.newMessage.files[0].url, { maxSizeMB: MAX_FILE_SIZE_MB, maxWidthOrHeight: IMAGE_MAX_HEIGHT_AND_WIDTH })
          messageOp.files = [{ type: FilesType.IMAGE }]
          messageOp.type = StrategyType.media
        }
        else if (this.newMessage.files[0]?.type === FilesType.PDF) {
          blob = this.newMessage.files[0].url
          messageOp.type = StrategyType.media
          messageOp.files = [{ type: FilesType.PDF }]
        }
      }

      // messages.updateMessage(this.createMessageFromNewMessage(StatusType.OUTGOING, message.communities))
      message.status = StatusType.OUTGOING
      const createdId = await api.createMessage([messageOp], blob)
      if (createdId) {
        this.newMessage.id = createdId
        const messageDetailsRes = await api.getMessageDetails(createdId)
        await userStore.initInfo()
        if (messageDetailsRes.success) {
          const msgDetails = messageDetailsRes.data?.[0] as MessageDetails | undefined
          // messages.updateMessage(this.createMessageFromNewMessage(StatusType.SENT, message.communities, msgDetails.received, msgDetails.total))
          message.status = StatusType.SENT
          message.received = msgDetails?.received || 0
          message.total = msgDetails?.total || 0
        }
      }
      else {
        throw 'fail'
      }
      this.clearFiles()
    } catch (e) {
      console.log('error', e)
      if (userStore.user) {
        messages.updateMessage(this.createMessageFromNewMessage(StatusType.FAILED))
      }
    }
  }

  createMessageFromNewMessage = (status: StatusType, communities: number[] = [], received: number = 0, total: number = 1): Message => {
    return {
      author_name: userStore.user.given_name,
      create_guid: this.newMessage.guid,
      created: new Date(),
      id: this.newMessage.id,
      labels: [this.newMessage.label],
      msg_strategy: this.newMessage.strategyType,
      payload: this.newMessage.payload,
      expiry: this.newMessage.expiry,
      senderId: userStore.user.user_id,
      senderName: userStore.user.given_name,
      received: received,
      total: total,
      updated: new Date(),
      status: status,
      communities: communities
    }
  }



}

const newMessageStore = new NewMessageStore()
export default newMessageStore
