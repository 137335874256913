import { useContext, createContext } from 'react'

import user from './user.store'
import snackBar from './snackbars.store'
import messages from './messages.store'
import newMessage from './newMessage.store'

export const stores = {
  user,
  messages,
  snackBar,
  newMessage
}

export {
  user,
  messages,
  snackBar,
  newMessage
}

const storesContext = createContext(stores)

export default function useStore() {
  return useContext<typeof stores>(storesContext)
}
