import React, { FC } from 'react'
import styled from 'styled-components'

import Appbar, { FooterAppbar } from './Appbar'
import Accessibility from './Accessibility'

const LayoutContainer = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
`

const Main = styled.div`
  display: flex;
  flex: 1;
`
const Content = styled.div`
  display: flex;
  flex-direction:row;
  flex:1;
  /* padding:0.5rem; */
  overflow:hidden;
`

const Layout: FC = ({ children }) => (
  <LayoutContainer>
    <Appbar />
    {/* <Accessibility /> */}
    <Content >
      <Main>
        {children}
      </Main>
    </Content>
    <FooterAppbar />
  </LayoutContainer>
)

export default Layout
