import { format, isWithinInterval, getDay, isToday, isYesterday, subDays, isThisYear, addDays } from 'date-fns'

export function formatDate(date: Date, t: any) {
  const d = date
  if (isToday(d)) return t`date:today`
  if (isYesterday(d)) return t`date:yesterday`
  return isWithinInterval(d, { start: subDays(new Date(), 9), end: new Date() }) ? t(getDateFromNumber(getDay(d))) : isThisYear(d) ? format(d, 'dd.MM') : format(d, 'dd.MM.yyyy')
}
export function formatDateTime(date: Date, t: any) {
  const d = date
  if (isToday(d)) return t`date:today`
  if (isYesterday(d)) return t`date:yesterday`
  return isWithinInterval(d, { start: new Date(), end: addDays(new Date(), 9) }) ? t(getDateFromNumber(getDay(d))) : isThisYear(d) ? format(d, 'dd.MM') : format(d, 'dd.MM.yyyy')
}
const getDateFromNumber = (day: number) => {
    switch (day) {
      case 0: return 'date:sunday'
      case 1: return 'date:monday'
      case 2: return 'date:tuesday'
      case 3: return 'date:wednesday'
      case 4: return 'date:thursday'
      case 5: return 'date:friday'
      case 6: return 'date:saturday'
    }
  }