import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { AppBar, Toolbar, Tooltip } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import logoIcon from 'assets/icons/Logo.svg'
import userIcon from 'assets/icons/UserLogo.svg'
import { Space, Image, theme, Row, Button } from 'ui'
import useStore from 'store'
import { useTranslation } from 'react-i18next'
import packageJson from '../../../package.json'
import AddIcon from '@material-ui/icons/Add';
import isMobile from 'utils/isMobile'

const StyledAppBar = styled(AppBar)`
  box-shadow: none;
  background-color: var(--dark-back);
  color: var(--white);
`
const StyledTopbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  color: var(--white);
`
const StyledTopbarMobile = styled(Toolbar)`
  padding: 10px;
`
const UserIcon = styled(Image)`
@media (max-width: 768px) {
  padding: 0 2px 0 5px;
}
  padding: 0 20px 0 20px;
`
const Text = styled.div`
@media (max-width: 768px) {
  font-size: 12px;
}
font-size: ${theme.fontSize.small};
`
const Logo = styled.img`
@media (max-width: 768px) {
  height:50%;
  width:50%;
}
`
const StyledLogout = styled.div`
  display:inline-flex;
  border-bottom: 1px solid white;
  cursor:pointer;
`
const NewMessageButton = styled(Button)`
  background-color:${theme.color.blandGray};
  color:${theme.color.black};
  align-items:center;
  justify-content:center;
`
const Appbar: FC = () => {
  const { t } = useTranslation('app')
  const { user, messages } = useStore()
  const { nick_name = '', given_name = '', family_name = '' } = user.user || {}
  const fullName = `${given_name} ${nick_name ? '(' + nick_name + ')' : ''} ${family_name}`

 const openUserListTable = () => {
  if (user.userInfo?.name) {
    user.openUserListTable(!user.userListTableView, user.userInfo?.id)
    if(!user.userListTableView == false) {
      messages.setActiveTooltip(null)
    }
  }
 } 

  if (isMobile()) {
    return (
      <StyledAppBar id='app-bar' position='static'>
        <StyledTopbarMobile>
          <Row
          // onClick={handleClick}
          >
            <UserIcon src={userIcon} />
            <Row justify='between'>
              <Text>{`${t`hi`}, ${fullName}`}</Text>
              <Text>|</Text>
              <Space width={1} />
              <StyledLogout onClick={user.logout}>{t`disconnect`}</StyledLogout>
              <Space width={1} />

              <Text>|</Text>
              <Space width={1} />

            </Row>
          </Row>
          <Tooltip title={<span style={{ fontSize: "15px" }}>{packageJson.version}</span>}>
            <div>
              <Row justify='center' align='center'>
              <Text style={{ cursor: 'pointer' }} onClick={openUserListTable}>{user.userInfo?.name || ''}</Text>
              </Row>
            </div>
          </Tooltip>
        </StyledTopbarMobile>
      </StyledAppBar>

    )
  } else
    return (
      <StyledAppBar id='app-bar' position='static'>
        <StyledTopbar>
          <Row
          // onClick={handleClick}
          >
            <UserIcon src={userIcon} />
            <Row justify='between'>
              <Text>{`${t`hi`}, ${fullName}`}</Text>
              <Space width={1} />
              <Text>|</Text>
              <Space width={1} />
              <StyledLogout onClick={user.logout}>{t`disconnect`}</StyledLogout>
            </Row>
          </Row>
          <Tooltip title={<span style={{ fontSize: "15px" }}>{packageJson.version}</span>}>
            <div>
              <Row justify='center' align='center'>
                <Space width={1} />
                <Text style={{ cursor: 'pointer' }} onClick={openUserListTable}>{user.userInfo?.name || ''}</Text>
                <Space width={1} />
                <Logo src={logoIcon} alt='Mekome logo' />
              </Row>
            </div>
          </Tooltip>
        </StyledTopbar>
      </StyledAppBar>
    )
}

export default observer(Appbar)
